import style from './index.module.scss';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';

const TeamSection = () => {
  return (
    <section id='team' className={style.team}>
      <div className='__container'>
        <div className={style.team__title}>TEAM</div>
        <div className={style.teamBody}>
          <Card
            img={img1}
            name={'Slicepie'}
            pos={'CEO'}
            text={
              'Advisor, Investor, Founder of RoOLZ. Former AWS & Salesforce.'
            }
          />
          <Card
            img={img2}
            name={'A Series of Events'}
            pos={'COO'}
            text={
              'Over 6 years of experience in strategy, PM, PO, start-ups, and process design.'
            }
          />
          <Card
            img={img3}
            name={'Hephaestus'}
            pos={'CTO'}
            text={'Ex. Head of Tech Sdao & dev. Singularitynet'}
          />
          <Card
            img={img4}
            name={'Next Roy'}
            pos={'HEAD OF CONTENT'}
            text={'Founder of Atrium.art.'}
          />
          <Card
            img={img5}
            name={'Plank Time'}
            pos={'HEAD OF infrastructure'}
            text={'8 Years at AWS.'}
          />
          <Card
            img={img6}
            name={'cold brew'}
            pos={'ADVISOR'}
            text={'Ex. COO @ AAV.'}
          />
        </div>
      </div>
    </section>
  );
};

const Card = ({ img, name, pos, text }) => {
  return (
    <div className={style.card}>
      <div className={style.card__img}>
        <img src={img} alt='' />{' '}
      </div>
      <div className={style.cardContent}>
        <div className={style.cardContent__name}>{name}</div>
        <div className={style.cardContent__pos}>{pos}</div>
        <div className={style.cardContent__text}>{text}</div>
      </div>
    </div>
  );
};

export default TeamSection;

import { Swiper, SwiperSlide } from 'swiper/react';
import style from './index.module.scss';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';
import { useMediaQuery } from 'usehooks-ts';
// import { EffectCards } from './slider/effect';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';
import img8 from './assets/img8.jpg';
import img9 from './assets/img9.jpg';
import img10 from './assets/img10.jpg';
import img11 from './assets/img11.jpg';
import img12 from './assets/img12.jpg';
import img13 from './assets/img13.jpg';
import img14 from './assets/img14.jpg';
import img15 from './assets/img15.jpg';
import img16 from './assets/img16.jpg';
import img17 from './assets/img17.jpg';
import img18 from './assets/img18.jpg';
import img19 from './assets/img19.jpg';
import img20 from './assets/img20.jpg';
import img21 from './assets/img21.jpg';

const CollectionSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <section id='collection' className={style.collection}>
      <div className='__container'>
        <div className={style.collection__title}>collection</div>
        <div className={style.collectionBody}>
          {!isTablet && (
            <Swiper
              effect={'cards'}
              centeredSlides
              initialSlide={3}
              cardsEffect={{
                slideShadows: true,
                rotate: true,
                perSlideRotate: 0,
                perSlideOffset: 70,
              }}
              grabCursor={true}
              modules={[EffectCards]}
              className={style.collectionBody__slider}
            >
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img1} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img2} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img3} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img4} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img5} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img6} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img7} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img8} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img9} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img10} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img11} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img12} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img13} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img14} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img15} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img16} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img17} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img18} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img19} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img20} alt='' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={style.collectionBody__card}>
                  <img src={img21} alt='' />
                </div>
              </SwiperSlide>
            </Swiper>
          )}
          {isTablet && (
            <div className={style.collectionBody__marquee}>
              <div className={style.collectionBody__marqueeRow}>
                <div className={style.content}>
                  <img src={img1} alt='' />
                  <img src={img2} alt='' />
                  <img src={img3} alt='' />
                  <img src={img4} alt='' />
                  <img src={img5} alt='' />
                  <img src={img6} alt='' />
                  <img src={img7} alt='' />
                  <img src={img8} alt='' />
                  <img src={img9} alt='' />
                  <img src={img10} alt='' />
                  <img src={img11} alt='' />
                </div>
              </div>
              <div className={style.collectionBody__marqueeRow}>
                <div className={style.content}>
                  <img src={img12} alt='' />
                  <img src={img13} alt='' />
                  <img src={img14} alt='' />
                  <img src={img15} alt='' />
                  <img src={img16} alt='' />
                  <img src={img17} alt='' />
                  <img src={img18} alt='' />
                  <img src={img19} alt='' />
                  <img src={img20} alt='' />
                  <img src={img21} alt='' />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={style.collectionBottom}>
          <div className={style.collectionBottom__text}>
            A collection of one-of-a-kind hand-drawn NFTs <br /> launched on the
            TON Blockchain in February 2024.
          </div>
          <a
            href='https://getgems.io/collection/EQCLN0mc5zJwjBAxhwtpQFlPq2nLoA5HR0pWbt5lXObX5oqa'
            target='_blank'
            rel='noreferrer'
            className={style.collectionBottom__button}
          >
            Buy on Getgems
            <div className={style.bg}>
              <svg
                width='260'
                height='61'
                viewBox='0 0 260 61'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.621199 30.5L22.7391 0.5H236.796L259.374 30.5L236.796 60.5H22.7391L0.621199 30.5Z'
                  fill='#ECAA2B'
                  fill-opacity='0.48'
                  stroke='url(#paint0_linear_1_397)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_1_397'
                    x1='0'
                    y1='30.5'
                    x2='260'
                    y2='30.5'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='white' />
                    <stop offset='1' stop-color='white' stop-opacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default CollectionSection;

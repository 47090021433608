import style from './index.module.scss';
import img from './assets/img.png';
import imgMob from './assets/img-mob.png';
import { useMediaQuery } from 'usehooks-ts';

const ApplicationSection = () => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <section id='application' className={style.application}>
      <div className='__container'>
        <div className={style.application__title}>application</div>
        <div className={style.applicationBody}>
          <div className={style.applicationBody__img}>
            <img src={isMobile ? imgMob : img} alt='' />
          </div>
          <div className={style.applicationBody__card}>
            <div className={style.applicationBody__cardValue}>2,000,000 +</div>
            <div className={style.applicationBody__cardText}>
              Followers <br />
              on Telegram and X
            </div>
          </div>
          <div className={style.applicationBody__card}>
            <div className={style.applicationBody__cardValue}>400,000 +</div>
            <div className={style.applicationBody__cardText}>
              Monthly Active Users <br />
              on Telegram Bot
            </div>
          </div>
          <div className={style.applicationBody__card}>
            <div className={style.applicationBody__cardValue}>80,000 +</div>
            <div className={style.applicationBody__cardText}>
              Daily Active Users <br />
              on Telegram Game
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplicationSection;

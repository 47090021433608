import style from './index.module.scss';
import { siteContent } from '../../siteContent';
import logo from './assets/logo.png';
import { useMediaQuery } from 'usehooks-ts';

const Footer = () => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <footer className={style.footer}>
      <div className='__container'>
        <div className={style.footerBody}>
          <div className={style.footerBody__info}>
            <div className={style.footerBody__infoLogo}>
              <img src={logo} alt='' />
            </div>
            <div className={style.footerBody__infoText}>
              A passion project <br />
              by bear market survivors
            </div>
          </div>
          {isTablet && (
            <>
              <div className={style.footerBody__social}>
                <a
                  href={siteContent.social.x.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__socialItem}
                >
                  {siteContent.social.x.icon}
                </a>
                <a
                  href={siteContent.social.ig.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__socialItem}
                >
                  {siteContent.social.ig.icon}
                </a>
                <a
                  href={siteContent.social.tg.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__socialItem}
                >
                  {siteContent.social.tg.icon}
                </a>
              </div>
              <div className={style.footerBody__links}>
                <a
                  href={siteContent.social.x.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__linksItem}
                >
                  Quest-Bot
                </a>
                <a
                  href={siteContent.social.ig.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__linksItem}
                >
                  GitBook
                </a>
                <a
                  href={siteContent.social.tg.link}
                  target='_blank'
                  rel='noreferrer'
                  className={style.footerBody__linksItem}
                >
                  Collection
                </a>
              </div>
            </>
          )}
          <div className={style.footerBody__col}>
            <div className={style.footerBody__colTitle}>Socials</div>
            <ul>
              <li>
                <a
                  href={siteContent.social.tg.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  Telegram
                </a>
              </li>
              <li>
                <a
                  href={siteContent.social.x.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  x (twitter)
                </a>
              </li>
              <li>
                <a
                  href={siteContent.social.ig.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  instagram
                </a>
              </li>
            </ul>
          </div>
          <div className={style.footerBody__col}>
            <div className={style.footerBody__colTitle}>project</div>
            <ul>
              <li>
                <a
                  href={siteContent.social.tg.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  quest-bot
                </a>
              </li>
              <li>
                <a
                  href={siteContent.social.x.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  GitBook
                </a>
              </li>
              <li>
                <a
                  href={siteContent.social.ig.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  collection
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

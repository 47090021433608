import { useState } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Modal from './modules/Modal';
import AboutSection from './sections/About';
import GallerySection from './sections/Gallery';
import JoinSection from './sections/Join';
import MainSection from './sections/Main';
import ProductsSection from './sections/Products';
import VideoModal from './modules/VideoModal';

import animeMp4 from './files/anime.mp4';
import gameMp4 from './files/game.mp4';
import SectionPagination from './components/SectionPagination/SectionPagination';
import CollectionSection from './sections/Collection';
import AnimeSection from './sections/Anime';
import RoolGame from './sections/RoolGame';
import PartnersSection from './sections/Partners';
import TeamSection from './sections/Team';
import ApplicationSection from './sections/Application';

function App() {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  // const [videoModalSrc, setVideoModalSrc] = useState('game');

  // const openModal = () => {
  //   document.body.classList.add('_lock');
  //   setIsModalOpen(true);
  // };
  // const closeModal = () => {
  //   document.body.classList.remove('_lock');
  //   setIsModalOpen(false);
  // };
  // const openVideoModal = (videoSrc) => {
  //   document.body.classList.add('_lock');
  //   setIsVideoModalOpen(true);
  //   if (videoSrc === 'game') {
  //     setVideoModalSrc('game');
  //   } else {
  //     setVideoModalSrc('');
  //   }
  // };
  // const closeVideoModal = () => {
  //   document.body.classList.remove('_lock');
  //   setIsVideoModalOpen(false);
  // };

  return (
    <main>
      <div className='wrapper'>
        <Header />

        <SectionPagination />

        {/* {isModalOpen && <Modal closeModal={closeModal} />}
        {isVideoModalOpen && (
          <VideoModal
            videoSrc={videoModalSrc === 'game' ? gameMp4 : animeMp4}
            closeModal={closeVideoModal}
          />
        )} */}

        <div className='page'>
          <MainSection />
          <CollectionSection />
          <AnimeSection />
          {/* <ProductsSection
          // openModal={openModal}
          // openVideoModal={openVideoModal}
          /> */}
          <GallerySection />
          <RoolGame />
          <ApplicationSection />
          <AboutSection />
          <PartnersSection />
          <TeamSection />
          {/* <JoinSection /> */}
        </div>
        <Footer />
      </div>
    </main>
  );
}

export default App;

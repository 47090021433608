import { useEffect, useState } from 'react';
import style from './SectionPagination.module.scss';

const SectionPagination = () => {
  const [currentSection, setCurrentSection] = useState('main');

  useEffect(() => {
    const main = document.querySelector('#main');
    const collection = document.querySelector('#collection');
    const anime = document.querySelector('#anime');
    const application = document.querySelector('#application');
    const token = document.querySelector('#token');
    const partners = document.querySelector('#partners');
    const team = document.querySelector('#team');

    const Visible = function (target) {
      // Все позиции элемента
      var targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight,
        };

      if (
        targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        targetPosition.left < windowPosition.right
      ) {
        // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код
        // console.clear();
        // console.log('Вы видите элемент :)');
        return true;
      } else {
        return false;
        // Если элемент не видно, то запускаем этот код
        // console.clear();
      }
    };

    window.addEventListener('scroll', () => {
      Visible(main) && setCurrentSection('main');
      Visible(collection) && setCurrentSection('collection');
      Visible(anime) && setCurrentSection('anime');
      Visible(application) && setCurrentSection('application');
      Visible(token) && setCurrentSection('token');
      Visible(partners) && setCurrentSection('partners');
      Visible(team) && setCurrentSection('team');
    });
  }, []);

  return (
    <div className={style.pagination}>
      <div
        className={`${style.pagination__item} 
        ${currentSection === 'main' && style.active}
        `}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'collection' && style.active
        }`}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'anime' && style.active
        }`}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'application' && style.active
        }`}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'token' && style.active
        }`}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'partners' && style.active
        }`}
      >
        <span></span>
      </div>
      <div
        className={`${style.pagination__item} ${
          currentSection === 'team' && style.active
        }`}
      >
        <span></span>
      </div>
    </div>
  );
};

export default SectionPagination;

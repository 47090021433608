import style from './index.module.scss';
import img from './assets/img.jpg';
import videoBg from './assets/bg.mp4';
import { siteContent } from '../../siteContent';
import { Fade } from 'react-reveal';

const MainSection = () => {
  return (
    <section id='main' className={style.main}>
      <div className={style.main__video}>
        <img src={img} alt='' />
        <video src={videoBg} loop muted autoPlay playsInline></video>
      </div>
      {/* <video src=""></video> */}
      <div className={`__container ${style.con}`}>
        <div className={style.mainBody__social}>
          <a
            href={siteContent.social.x.link}
            target='_blank'
            rel='noreferrer'
            className={style.mainBody__socialItem}
          >
            {siteContent.social.x.icon}
          </a>
          <a
            href={siteContent.social.tg.link}
            target='_blank'
            rel='noreferrer'
            className={style.mainBody__socialItem}
          >
            {siteContent.social.tg.icon}
          </a>
        </div>
        <div className={style.mainBody}>
          <div className={style.mainBody__title}>
            <Fade>Roolz</Fade>
          </div>
          <div className={style.mainBody__text}>
            <Fade>
              An interactive storyworld with Epic Content, Digital Collectibles,
              Playable Mini-Games, and $GODL token.
            </Fade>
          </div>
        </div>
        <div className={style.mainBody__icon}>
          <svg
            width='20'
            height='46'
            viewBox='0 0 20 46'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='0.5'
              y='8.5'
              width='19'
              height='29'
              rx='9.5'
              stroke='white'
            />
            <rect x='9' y='15' width='2' height='6' rx='1' fill='white' />
            <path
              d='M6 4L10 1L14 4'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M14 42L10 45L6 42'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
        {/* <a
          href={siteContent.social.tg.link}
          rel='noreferrer'
          target='_blank'
          className={style.mainTelegram}
        >
          <Fade>
            <div className={style.mainTelegram__icon}>
              {siteContent.social.tg.icon}
            </div>
          </Fade>
          <div className={style.mainTelegram__text}>
            <Fade>join telegram</Fade>
          </div>
        </a> */}
      </div>
    </section>
  );
};

export default MainSection;

import style from './index.module.scss';
import img from './assets/img.jpg';

const RoolGame = () => {
  return (
    <section className={style.game}>
      <div className='__container'>
        <div className={style.gameBody}>
          <div className={style.gameBody__card}>
            <div className={style.gameBody__cardTitle}>roolz anime</div>
            <div className={style.gameBody__cardText}>
              <p>
                An epic saga spanning 25 short-form episodes sharing the story
                of RoOLZ.
              </p>
              <p>
                Produced by the former Attack on Titan team. The RoOLZ trailer
                premiered in January, with episodes set to debut in summer 2024.
              </p>
            </div>
          </div>
          <div className={style.gameBody__card}>
            <div className={style.gameBody__cardTitle}>over 3M views</div>
            <div className={style.gameBody__cardText}>
              <p>
                After our trailer surpassed 3 million views, we set a goal to
                become the first-ever TON project to create an original anime
                series for the modern-day audience.
              </p>
            </div>
          </div>
          <div className={style.gameBody__img}>
            <img src={img} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoolGame;

import style from './index.module.scss';
import Marquee from 'react-fast-marquee';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';
import img8 from './assets/img8.jpg';
import img9 from './assets/img9.jpg';
import img10 from './assets/img10.jpg';
import img11 from './assets/img11.jpg';
import img12 from './assets/img12.jpg';
import img13 from './assets/img13.jpg';
import img14 from './assets/img14.jpg';
import img15 from './assets/img15.jpg';
import img16 from './assets/img16.jpg';

import { useMediaQuery } from 'usehooks-ts';

const GallerySection = () => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <section className={style.gallery}>
      {/* <div className='__container'> */}
      <div className={style.galleryBody}>
        {!isMobile && (
          <>
            <Marquee
              direction={isMobile ? 'up' : 'left'}
              className={style.galleryBody__row}
            >
              <div className={style.galleryBody__rowContent}>
                <img src={img1} alt='' />
                <img src={img2} alt='' />
                <img src={img3} alt='' />
                <img src={img4} alt='' />
                <img src={img5} alt='' />
                <img src={img6} alt='' />
                <img src={img7} alt='' />
                <img src={img8} alt='' />
              </div>
            </Marquee>
            <Marquee
              speed={45}
              direction={isMobile ? 'up' : 'left'}
              className={style.galleryBody__row}
              autoFill
            >
              <div className={style.galleryBody__rowContent}>
                <img src={img9} alt='' />
                <img src={img10} alt='' />
                <img src={img11} alt='' />
                <img src={img12} alt='' />
                <img src={img13} alt='' />
                <img src={img14} alt='' />
                <img src={img15} alt='' />
                <img src={img16} alt='' />
              </div>
            </Marquee>
          </>
        )}

        {isMobile && (
          <>
            <div className={style.galleryBody__row}>
              <div className={style.galleryBody__rowContent}>
                <img src={img1} alt='' />
                <img src={img2} alt='' />
                <img src={img3} alt='' />
                <img src={img4} alt='' />
                <img src={img5} alt='' />
                <img src={img6} alt='' />
                <img src={img7} alt='' />
                <img src={img8} alt='' />
                <img src={img1} alt='' />
                <img src={img2} alt='' />
                <img src={img3} alt='' />
                <img src={img4} alt='' />
                <img src={img5} alt='' />
                <img src={img6} alt='' />
                <img src={img7} alt='' />
                <img src={img8} alt='' />
              </div>
            </div>
            <div className={style.galleryBody__row}>
              <div className={style.galleryBody__rowContent}>
                <img src={img9} alt='' />
                <img src={img10} alt='' />
                <img src={img11} alt='' />
                <img src={img12} alt='' />
                <img src={img13} alt='' />
                <img src={img14} alt='' />
                <img src={img15} alt='' />
                <img src={img16} alt='' />
                <img src={img9} alt='' />
                <img src={img10} alt='' />
                <img src={img11} alt='' />
                <img src={img12} alt='' />
                <img src={img13} alt='' />
                <img src={img14} alt='' />
                <img src={img15} alt='' />
                <img src={img16} alt='' />
              </div>
            </div>
          </>
        )}
      </div>
      {/* </div> */}
    </section>
  );
};

export default GallerySection;

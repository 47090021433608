import style from './index.module.scss';
// import elemLeft from './assets/elem-left.svg';
// import elemRight from './assets/elem-right.svg';
import bg from './assets/bg.svg';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { siteContent } from '../../siteContent';

const Header = () => {
  const headerRef = useRef(null);
  let [prevScrollpos, setPrevScrollpos] = useState(0);
  const [currentSection, setCurrentSection] = useState('');
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:991px)');

  function handleSticky() {
    const sticky = headerRef.current.offsetTop;
    if (window.pageYOffset > sticky) {
      // headerRef.current.classList.add('_scrolled');
      headerRef.current.classList.add(style.scrolled);
    } else {
      // headerRef.current.classList.remove('_scrolled');
      headerRef.current.classList.remove(style.scrolled);
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        headerRef.current.classList.add(style.visible);
      } else {
        headerRef.current.classList.remove(style.visible);
      }
      // prevScrollpos = currentScrollPos;
      setPrevScrollpos(currentScrollPos);
    };
    window.addEventListener('scroll', handleSticky);
  }, [prevScrollpos]);

  useEffect(() => {
    const main = document.querySelector('#main');
    // const products = document.querySelector('#products');
    const about = document.querySelector('#about');
    // const join = document.querySelector('#join');

    const Visible = function (target) {
      // Все позиции элемента
      var targetPosition = {
          top: window.pageYOffset + target.getBoundingClientRect().top,
          left: window.pageXOffset + target.getBoundingClientRect().left,
          right: window.pageXOffset + target.getBoundingClientRect().right,
          bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
        },
        // Получаем позиции окна
        windowPosition = {
          top: window.pageYOffset,
          left: window.pageXOffset,
          right: window.pageXOffset + document.documentElement.clientWidth,
          bottom: window.pageYOffset + document.documentElement.clientHeight,
        };

      if (
        targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        targetPosition.left < windowPosition.right
      ) {
        // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код
        // console.clear();
        // console.log('Вы видите элемент :)');
        return true;
      } else {
        return false;
        // Если элемент не видно, то запускаем этот код
        // console.clear();
      }
    };

    window.addEventListener('scroll', () => {
      // Visible(main) && setCurrentSection('main');
      // Visible(products) && setCurrentSection('products');
      // Visible(about) && setCurrentSection('about');
      // Visible(join) && setCurrentSection('join');
    });
  }, []);

  const scrollToSection = (section) => {
    const item = document.querySelector(
      `#${section.toLocaleLowerCase().split(' ').join('')}`
    );
    setIsBurgerOpen(false);

    window.scrollTo({
      top: item.offsetTop,
      behavior: 'smooth',
    });
  };

  const handleBurger = () => {
    if (isBurgerOpen) {
      setIsBurgerOpen(false);
    } else {
      setIsBurgerOpen(true);
    }
  };

  return (
    <>
      <header ref={headerRef} className={style.header}>
        <div className={`${style.con} __container`}>
          <nav className={style.headerBody}>
            <div className={style.headerBody__bg}>
              <img src={bg} alt='' />
            </div>
            {/* <div className={style.headerBody__elem}>
            <img src={elemLeft} alt='' />
          </div> */}
            <ul>
              <li>
                <div onClick={() => scrollToSection('main')}>home</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('collection')}>
                  collection
                </div>
              </li>
              <li>
                <div onClick={() => scrollToSection('anime')}>anime</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('application')}>
                  application
                </div>
              </li>
              <li>
                <div onClick={() => scrollToSection('token')}>token</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('partners')}>
                  partnerships
                </div>
              </li>
              <li>
                <div onClick={() => scrollToSection('team')}>team</div>
              </li>
              {/* <li className={currentSection === 'products' && style.active}>
              <div onClick={() => scrollToSection('products')}>products</div>
            </li>
            <li className={currentSection === 'about' && style.active}>
              <div onClick={() => scrollToSection('about')}>$godl</div>
            </li>
            <li className={currentSection === 'join' && style.active}>
              <div onClick={() => scrollToSection('join')}>join</div>
            </li>
            <li className={currentSection === 'join' && style.active}>
              <div onClick={() => scrollToSection('join')}>join</div>
            </li> */}
            </ul>
            <div
              onClick={handleBurger}
              className={`${style.headerBody__burger} ${
                isBurgerOpen && style.open
              }`}
            >
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8 16L8 0' stroke='white' stroke-width='2' />
                <path d='M16 8L1.86153e-07 8' stroke='white' stroke-width='2' />
              </svg>
            </div>
            {/* <div className={style.headerBody__elem}>
            <img src={elemRight} alt='' />
          </div> */}
          </nav>
        </div>
      </header>
      {isMobile && (
        <div className={`${style.menu} ${isBurgerOpen && style.open}`}>
          <div className='__container'>
            <ul className={style.menuList}>
              <li>
                <div onClick={() => scrollToSection('home')}>home</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('collection')}>
                  collection
                </div>
              </li>
              <li>
                <div onClick={() => scrollToSection('anime')}>anime</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('application')}>
                  application
                </div>
              </li>
              <li>
                <div onClick={() => scrollToSection('token')}>token</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('partners')}>partners</div>
              </li>
              <li>
                <div onClick={() => scrollToSection('team')}>team</div>
              </li>
            </ul>
            <div className={style.menuSocial}>
              <a
                href={siteContent.social.x.link}
                target='_blank'
                rel='noreferrer'
                className={style.menuSocial__item}
              >
                {siteContent.social.x.icon}
              </a>
              <a
                href={siteContent.social.ig.link}
                target='_blank'
                rel='noreferrer'
                className={style.menuSocial__item}
              >
                {siteContent.social.ig.icon}
              </a>
              <a
                href={siteContent.social.tg.link}
                target='_blank'
                rel='noreferrer'
                className={style.menuSocial__item}
              >
                {siteContent.social.tg.icon}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

import style from './index.module.scss';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';

const PartnersSection = () => {
  return (
    <section id='partners' className={style.partners}>
      <div className='__container'>
        <div className={style.partners__title}>PARTNERS</div>
        <div className={style.partnersBody}>
          <div className={style.partnersBody__item}>
            <img src={img1} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img2} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img3} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img4} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img5} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img6} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img7} alt='' />
          </div>
          <div className={style.partnersBody__item}>
            <img src={img8} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;

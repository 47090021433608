import style from './index.module.scss';
import video from './assets/godl.mp4';
import { Fade } from 'react-reveal';

const AboutSection = () => {
  return (
    <section id='token' className={style.about}>
      <div className='__container'>
        <div className={style.aboutBody}>
          <Fade up>
            <div className={style.aboutBody__title}>GODS HODL $GODL</div>
          </Fade>
          <Fade up>
            <div className={style.aboutBody__video}>
              <video muted loop autoPlay playsInline src={video}></video>
            </div>
          </Fade>
          <Fade up>
            <div className={style.aboutBody__text}>
              The main token that fuels RoOLZ ecosystem. Start your quests on
              Telegram for $GODL points now!
            </div>
          </Fade>
          <Fade up>
            <a
              href='https://t.me/ROoLZQuest_bot'
              target='_blank'
              rel='noreferrer'
              className={style.aboutBody__link}
            >
              join telegram
              <svg
                width='260'
                height='61'
                viewBox='0 0 260 61'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.621199 30.5L22.7391 0.5H236.796L259.374 30.5L236.796 60.5H22.7391L0.621199 30.5Z'
                  fill='#ECAA2B'
                  fill-opacity='0.48'
                  stroke='url(#paint0_linear_1_454)'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_1_454'
                    x1='0'
                    y1='30.5'
                    x2='260'
                    y2='30.5'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stop-color='white' />
                    <stop offset='1' stop-color='white' stop-opacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </a>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

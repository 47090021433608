import style from './index.module.scss';
import video from '../../files/anime.mp4';
import { useEffect, useRef, useState } from 'react';

const AnimeSection = () => {
  const videoRef = useRef(null);
  const [isVideoPlay, setIsVideoPlay] = useState(false);

  const playVideo = () => {
    videoRef.current.play();
    setIsVideoPlay(true);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('play', (event) => {
        setIsVideoPlay(true);
      });
      videoRef.current.addEventListener('pause', (event) => {
        setIsVideoPlay(false);
      });
    }
  }, [isVideoPlay, videoRef]);

  return (
    <section id='anime' className={style.anime}>
      <div className={`${style.con} __container`}>
        <div className={style.animeBody}>
          <div className={style.animeBody__title}>original anime</div>
          <div className={style.animeBody__video}>
            <button
              onClick={playVideo}
              className={`${style.animeBody__videoPlay} ${
                isVideoPlay && style.hidden
              }`}
            >
              <svg
                width='74'
                height='74'
                viewBox='0 0 74 74'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M0 74V11V0L74 37L0 74Z' fill='white' />
              </svg>
            </button>
            <video src={video} ref={videoRef} controls={isVideoPlay}></video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AnimeSection;
